import React, { forwardRef,useContext, useState, useImperativeHandle, useRef, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { APIGet, APIPost } from '../../API/APIRequest';
import { useElements, useStripe, Elements, PaymentElement } from '@stripe/react-stripe-js';
import '../../../i18n'
import { PaymentFormContext, updateBillingAddress } from '../PaymentFormReducer';
import InputWrapperRefacture from '../../UI/Inputs/InputWrapperRefacture';
import ErrorBox from '../../UI/ErrorBox/ErrorBox';
import { gtmEventTriggers } from '../../Utilities/GtmFunctions';
import MessageBox from '../../UI/MessageBox/MessageBox';


export const InitialiseStripe = (props) => {
    return new Promise((resolve, reject) => {
        let basket = props.basket;
        const data = new FormData();
        let action = "createpaymentintent";
        if (basket.offertypename === "paid free trial" || basket.offertypename === "free trial")
        {
            action = "createsetupintent";
        }
        data.append("basketId", basket.id);
        data.append("paymentTypeId", props.paymentSelection);
        data.append("publisherCurrencyId", basket.publishercurrencyid);
        APIPost({
            "controller": "stripe",
            "action": action,
            "environment": null,
            "data": data
        })
            .then((response) => {
                let publicKey = response.data.paymentproviderusername;
                
                let stripeResult = {
                    "publickey": publicKey,
                    "secret": response.data.secretkey,
                    "intentid": response.data.stripeintentid
                };
              resolve(stripeResult);
            }).catch((error) => {
                
                reject(error);
            });
        });

}

export const UpdateStripePaymentRequest = (props) => {
    return new Promise((resolve, reject) => {
        let basket = props.basket;
        console.log(basket);
        let action = "updatepaymentintent";
        if (basket.offertypename === "paid free trial") {
            action = "updatesetupintent";
        }
        const data = new FormData();
        data.append("basketId", basket.id);
        data.append("orderId", props.orderId);
        data.append("stripeIntentId", props.stripeIntentId);
        APIPost({
            "controller": "stripe",
            "action": action,
            "environment": null,
            "data": data
        })
            .then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
    });
}

export const GetCardInfo = (props) => {
    return new Promise((resolve, reject) => {
        APIGet({
            "controller": "stripe",
            "action": "getcardinfo",
            "environment": null,
            "identifier": props.fid
        }).then((cardResponse) => {
            resolve(cardResponse.data);
        }).catch((cardError) => {
            var unexpected = [];
            unexpected.push("error.unexpected");
            reject(unexpected);
        });
    });
}

export const StripeForm = forwardRef((props, ref) => {
    let options = {
        clientSecret: props.stripeCredentials.secret,
        appearance: {
            theme: 'stripe',
            variables: {
                colorPrimary: '#0570de',
                colorBackground: '#fafafa',
                colorText: '#30313d',
                colorDanger: '#df1b41', 
                borderRadius: '0px',
                fontSizeSm: '16px',
                colorText: "#00000",
                spacingGridRow: '24px',
                colorDanger: '#DD2B1B'
                // See all possible variables below
            },
            rules: {
                '.p-GridCell': {
                    marginBottom: '24px'
                },
                '.Label': {
                    paddingBottom: '8px',
                    fontWeight: '700',
                    fontSize:'14px'

                },
                '.Input': {
                    border: '1px solid transparent',
                    boxShadow: '0px 0px 0px 1px #1175D2',
                },
                '.Input--empty': {
                    border: '1px solid transparent',
                    boxShadow: '0px 0px 0px 1px #DCDCDC',
                },
                '.Input--empty:focus': {
                    border: '1px solid transparent',
                    boxShadow: '0px 0px 0px 2px #1175D2',
                },
                '.Input:focus': {
                    border: '1px solid transparent',
                    boxShadow: '0px 0px 0px 1px #1175D2',
                },
                '.p-Input--focused': {
                    border: '1px solid transparent',
                    boxShadow: '0px 0px 0px 1px #1175D2',
                },
                '.Input--invalid': {
                    boxShadow: '0px 0px 0px 1px #DD2B1B',
                    color: '#000',
                    border: '1px solid transparent',
                    backgroundColor: '#FFF4F4'
                }, 
                '.Input--invalid:after': {

                },
                '.Error': {
                    fontSize: '14px',
                    marginTop: '8px',
                    borderLeft: '2px solid #DD2B1B', 
                    paddingLeft:'8px'
                }
            }
        },
        loader: 'always'
    } 


     let stripePromise = loadStripe(props.stripeCredentials.publickey)
    
    return (
        <Elements stripe={stripePromise} options={options} >
            <StripeElement billingCountryCode={props.billingCountryCode} setErrorStates={props.setErrorStates} errorStates={props.errorStates} creditCardHolderName={props.creditCardHolderName} setCreditCardHolderName={props.setCreditCardHolderName} setIsLoading={props.setIsLoading} billingAddress={props.billingAddress} ref={ref} basket={props.basket} />
        </Elements>
    );
});

const StripeElement = forwardRef((props, ref) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errors, setErrors] = useState([]);
    const { t, i18n } = useTranslation();
    const {billingAddress, billingCountryCode, setErrorStates, errorStates, creditCardHolderName, setCreditCardHolderName}=props;
    const [cardHolderName, setCardHolderName] = useState();
  
    const stripeCardErrorHandler=(error)=>{
     
        if(error.code==="card_declined" ||
           error.code==="expired_card" ||
           error.code==="incorrect_cvc" ||
           error.code==="processing_error" ||
           error.code==="incorrect_number"){

             setErrorStates(errorStates=>({...errorStates, cardDeclined:true }))

            }
    }
    useImperativeHandle(ref, () => ({
       
        confirmPayment(event, isFreeTrial) {
         
            gtmEventTriggers("add_payment_info");
            sessionStorage.removeItem("stripeData");  //deletion once the process starts
          
            if (isFreeTrial) {
                stripe.confirmSetup({
                    //`Elements` instance that was used to create the Payment Element
                    elements,
                    confirmParams: {
                        payment_method_data: {
                            billing_details: {
                                name: creditCardHolderName,
                                address: {
                                    country: billingCountryCode ?? "GB",
                                    postal_code: billingAddress.POSTCODE ?? "SW1Y 4PH",
                                    // country: props.basket.countrycode ?? "GB",   
                                    // postal_code: props.basket.postcode ?? "SW1Y 4PH",  
                                },

                            }
                        },

                       
                        return_url: window.location.protocol + "//" + window.location.host + "/StripeResponseHandler/" + props.basket.id + + window.location.search,

                    },
                }).then((result) => {

                   if (result.error) {
                        stripeCardErrorHandler(result.error)
                        props.setIsLoading(false);
                    }
                }).catch(e => {
                    props.setIsLoading(false);

                });
            }
            else {
                stripe.confirmPayment({
                    //`Elements` instance that was used to create the Payment Element

                    elements,
                    confirmParams: {
                        payment_method_data: {
                            billing_details: {
                                name: creditCardHolderName,
                                address: {
                                    country: billingCountryCode ?? "GB",
                                    postal_code: billingAddress.POSTCODE ?? "SW1Y 4PH",
                                    // country: props.basket.countrycode ?? "GB",   
                                    // postal_code: props.basket.postcode ?? "SW1Y 4PH",  
                                },

                            }
                        },

                        
                        return_url: window.location.protocol + "//" + window.location.host + "/StripeResponseHandler/" + props.basket.id + window.location.search,

                    },
                }).then((result) => {
                   
                    if (result.error) {

                        stripeCardErrorHandler(result.error)
                        props.setIsLoading(false);
                    }

                }).catch(e => {
                    props.setIsLoading(false);

                });
            }
           
        },
        elements:elements,
        errors
    }));

    var options1 = {
        fields: {
            billingDetails: {
               
                address: { 
                    country: "never",
                    postalCode: "never"
                },
           
            },
       
        },      
        terms: {
            card: "never"
        },
        
        wallets: {
            applePay : "never",
            googlePay : "never",
            paypal:"never",

        }
    };

    var options2 = {
        fields: {
            billingDetails: {
               
                address: { 
                    country: "never",
                    postalCode: "auto"
                },
           
            },
       
        },      
        terms: {
            card: "never"
        },
        wallets: {
            applePay : "never",
            googlePay : "never",
            paypal:"never",

        }
    };

    useEffect(()=>{
    
    //initialising the state for cardHolderBlank error    
    setErrorStates(errorStates => ({ ...errorStates, cardHolderNameBlank: false }));
        
    }, [])
   
    const handleOnBlur = (value) => {
        setCreditCardHolderName(value)  //parent state 
    }

    const handleOnChange = (value) => {

        setCardHolderName(value);
        if (value) {
            setErrorStates(errorStates => ({ ...errorStates, cardHolderNameBlank: false }));   //local state
        }
    }

    return (
        <>
            
            <div className="item-container">
                <h5 className="module-subheading">{t('creditcardform.title')}</h5>
            </div> 

            {errorStates?.cardDeclined &&
                <div className="item-container">
                    <MessageBox messageState="error">{t('creditcardform.carddeclineerror')}</MessageBox>
                </div>}
              
             <div className="item-container">
                <div className="item-wrapper">
                    <InputWrapperRefacture
                        label={t('creditcardform.cardholderlabel')}
                        inputSize="full-width"
                        id="cardholder"
                        inputFieldType="text"
                        specs="text_input"
                        name="cardholder"
                        type="text"
                        invalidClass={errorStates.cardHolderNameBlank && "Invalid-input"}
                        aria-invalid={errorStates.cardHolderNameBlank}
                        aria-describedby="cardholder-blank-error"
                        value={cardHolderName}
                        onBlur={(e)=> {handleOnBlur(e.target.value)}}
                        onChange={(e) => {handleOnChange(e.target.value) }}
                    />
               
                {errorStates.cardHolderNameBlank ? <span role="alert" id='cardholder-blank-error'><ErrorBox icon="false">{t('creditcardform.blankcardholder')}</ErrorBox></span> : ""}

                </div>
            </div>
                {/* {billingAddress && billingAddress.POSTCODE ? <PaymentElement options={options1} />
                : <PaymentElement options={options2} />} */}
               <PaymentElement options={options1} />
        
            </>
            );

});

