import React, { Component, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../i18n'
import { Loading } from '../../Utilities/Loading'
import { APIGet, APIPost } from '../../API/APIRequest';
import { format } from 'react-string-format';
import useFindHeight from '../../../Hooks/useFindHeight';
import { GetADToken, SaveADToken } from '../../Utilities/TokenManager'
import { getOriginatingUrl } from '../../Utilities/GetUrlFunctions';

export function GetBasket(props) {
    return new Promise((resolve, reject) => {
      
        let basketStorage = sessionStorage.getItem(props.fid);
        if (basketStorage && !props.loadfromserver) {
            resolve(JSON.parse(basketStorage));
        }
        else {
            APIGet({
                "controller": "basket",
                "action": "getbasket",
                "data": props,
                "environment": null,
                "identifier": props.fid,
                "headers": [{
                    "key": "UserToken",
                    "value": props.userToken ?? ""
                }]
            }).then((response) => {
                if (response.data.success) {
                    console.log(response.data.basket);
                    sessionStorage.setItem("fid", response.data.basket.id);
                    sessionStorage.setItem(props.fid, JSON.stringify(response.data.basket));
                    let user = response.data.singleuselogintoken;
                    if (user && !props.userToken) {
                        let singleUseLoginToken = {
                            "name": user.userfullname,
                            "token": user.userToken,
                            "partyid": user.partyid,
                            "username": user.username
                        };
                        SaveADToken(singleUseLoginToken);
                    }
                    resolve(response.data.basket);
                }
                else {
                    reject(response.data.errors);
                }
            }).
                catch((error) => {
                    if (error?.response?.data?.errors) {
                        reject({messages : error.response.data.errors, originatingurl : error.response.data.originatingurl });
                    }
                   
                    var unexpected = [];
                    unexpected.push("error.unexpected");
                    reject({ messasges: unexpected, originatingurl: error.response.data.originatingurl });
                });
        }
    });
}

export function UpdateBasket(props) {
  
    return new Promise((resolve, reject) => {
        let basketJSON = JSON.stringify(props.basket);
        let basketStorage = sessionStorage.setItem(props.fid, basketJSON);
       
        if (props.localsaveonly) {
            resolve();
        }
        else {
            APIPost({
                "controller": "basket",
                "action": "updatebasket",
                "environment": null,
                "identifier": props.fid,
                data: basketJSON,
                "content_type": "json"
            }).then((response) => {
                if (response.data.success) {
                    resolve();
                }
                else {
                    reject(response.data.errors);
                }
            }).
                catch((error) => {
                      // only when server responds with 400
                      if (error.response && error.response.status === 400) {
                        const errorData = error.response.data;
                
                        if (errorData.errors && errorData.errors.includes("error.basketcompletedorder")) {
                          // Handle the specific error related to a completed order
                          reject(errorData.errors);
                        
                         } 
                      } 
                      else{
                          let unexpected = [];
                          unexpected.push("error.unexpected");
                          reject(unexpected);
                      }
                   
                });
        }
    });
}

export function SaveToOrder(props) {
    return new Promise((resolve, reject) => {
        APIPost({
            "controller": "basket",
            "action": "savetoorder",
            "environment": null,
            "identifier": props.fid,
            "content_type": "json",
            "data": props.data
        }).then((response) => {
            if (response.data.success) {
                resolve(response.data);
            }
            else {
                reject(response.data.errors);
            }
        }).
            catch((error) => {
               
                   // only when server responds with 400
                    if (error.response && error.response.status === 400) {
                      const errorData = error.response.data;
              
                      if (errorData.errors && errorData.errors.includes("error.basketcompletedorder")) {
                        // Handle the specific error related to a completed order
                        reject(errorData.errors);
                       } 
                    } 
                    else{
                        let unexpected = [];
                        unexpected.push("error.unexpected");
                        reject(unexpected);
                    }
                });
    });
}



export function Basket(props) {
    const { t, i18n } = useTranslation();
    const { basket, deliveryCountry} = props;
    const [basketCountry, setBasketCountry] = useState();
    const [observedDiv, elementHeight] = useFindHeight();
    const [loading, setLoading] = useState(true);
    const discountFlow = sessionStorage.getItem("discountApplied");
  
    useEffect(()=>{

        if (deliveryCountry) {
            setBasketCountry(deliveryCountry);
        }

      },[deliveryCountry])


    const displayData = () => {
        var basket = props.basket;
        var subtotal = basket.subtotal;
        var tax = basket.tax;
        var gross = basket.gross;

        return (
            <>
            {!props.isOnModal ?  
                <>  
                <div className = "item-container" >
                    <div className="item-wrapper" >
                        <a className="back-button" href={getOriginatingUrl(basket, "")}>
                            <span class="back-icon"> </span><span>{t('basket.changesubscription')}</span></a>
                    </div>
                    <div className="item-wrapper">
                        <h2 className="module-heading">{t('basket.title')}</h2>
                    </div>
                    <div className="item-wrapper"  >
                        <div className="item-field">
                         
                            <div
                                ref={observedDiv}
                                className={`item-wrapper ${!props.isOnModal && elementHeight > 135 && "customScroll customScrollerHeight"}`}> 
                              
                                {basket.lines?.map((line, index) => (
                                    
                                    <> <div className="two-columns-row">
                                            <div className="module-column">
                                                  <div className="module-block">
                                                <h3 className="module-subheading">{line.productvariantdisplayname ? line.productvariantdisplayname : line.productvariantname} </h3>
                                                {line.quantity > 1 && <span className="module-quantity-block">  x {line.quantity}</span>}
                                                  </div>
                                                  <p className="module-description">{line.productvariantdesc && line.productvariantdesc} </p>
                                                  <p className="module-description">{line.checkoutdescription && line.checkoutdescription} </p>
                                            </div>
                                        <div className="module-price">
                                            {basket.currencysymbol}{line.linetotalnet?.toFixed(2)}
                                            </div>
                                        </div>
                                        <div className="border-seperator"> </div>
                                     </>
                                ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="item-wrapper">
                        <div className="two-columns-row"> <span>{t('basket.subtotallabel')}</span>  <span className="order-price"> {basket.currencysymbol}{subtotal?.toFixed(2)}</span></div>
                        <div className="two-columns-row"> <span>{t('basket.taxlabel')}</span>  <span className="order-price">{basket.currencysymbol}{tax?.toFixed(2)}</span></div>
                        <div className="border-seperator"> </div>
                        <div className="two-columns-row"> <span className="order-price"><strong> Order total</strong></span>  <span className="order-price"> <strong>{basket.currencysymbol}{gross?.toFixed(2)}</strong></span></div>
                    </div>
                </div >
                <div className="block-container">
            <p>  {format(t('basket.regioninfo'), basketCountry)}</p>
            <a href={getOriginatingUrl(basket, "")}>{t('basket.changeregion')} </a>
        </div> 
                </> 
                :
                    <>
                        <div className="container-header" >
                                <div className="container" >
                                    <div className="item-wrapper">
                                        <h2 className="module-heading">{t('basket.title')}</h2>
                                    </div>
                                   </div>
                        </div>
                    
                            <div className="container summary" >

                            <div className="item-container" >
                            <div className="item-wrapper" >
                                <a className="back-button" href={getOriginatingUrl(basket, "")}>
                                    <span class="back-icon"> </span><span>{t('basket.mobilechangesubscription')}</span></a>
                            </div>
                      
                            <div className="item-wrapper"  >
                                <div className="item-field">

                                    <div className="item-wrapper">

                                        {basket.lines?.map((line, index) => (

                                            <> <div className="two-columns-row">
                                                <div className="module-column">
                                                    <div className="module-block">
                                                        <h3 className="module-subheading">{line.productvariantdisplayname ? line.productvariantdisplayname : line.productvariantname} </h3>
                                                        <span className="module-quantity-block">  x {line.quantity}</span>
                                                    </div>
                                                    <p className="module-description">{line.productvariantdesc && line.productvariantdesc} </p>
                                                </div>
                                                <div className="module-price">
                                                    {basket.currencysymbol}{line.linetotalnet?.toFixed(2)}
                                                </div>
                                            </div>
                                                <div className="border-seperator"> </div>
                                            </>
                                        ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="item-wrapper">
                                <div className="two-columns-row"> <span>{t('basket.subtotallabel')}</span>  <span className="order-price"> {basket.currencysymbol}{subtotal?.toFixed(2)}</span></div>
                                <div className="two-columns-row"> <span>{t('basket.taxlabel')}</span>  <span className="order-price">{basket.currencysymbol}{tax?.toFixed(2)}</span></div>
                                <div className="border-seperator"> </div>
                                <div className="two-columns-row"> <span className="order-price"><strong> Order total</strong></span>  <span className="order-price"> <strong>{basket.currencysymbol}{gross?.toFixed(2)}</strong></span></div>
                            </div>
                        </div >
                            <div className="block-container">
                            <p>  {format(t('basket.regioninfo'), basketCountry)}   <a href={getOriginatingUrl(basket, "")}>{t('basket.changeregion')} </a> </p>
                            </div>
                        </div>
                     
                </>
 

                }
            </>
                )

    }

    var content = displayData();
    return (
        <div>
            <Loading loading={props.loading} />
            {content}
        </div>
    )

}
