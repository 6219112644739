import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { format } from 'react-string-format';
import '../../../i18n'
import Checkbox from '../../UI/Checkbox/Checkbox';
import ErrorBox from '../../UI/ErrorBox/ErrorBox';
import { APIGet, getEnvironment } from '../../API/APIRequest';
import { useParams } from 'react-router';
import DOMPurify from 'dompurify';

export function MarketingPreferences(props, errorStates) {
    const { t, i18n } = useTranslation();
    const [isChecked, setIsChecked] = useState(false);
    const [dpa, setDpa] = useState("");
    const { environment } = useParams();
   

    const loadDPA = async () => {
   
        await APIGet({
            "controller": "auth",
            "action": "getregistrationdpa",
            "environment": environment,
        })
            .then(function (response) {
                let dpa= response.data?.dpaquestion;
                setDpa(dpa); //this is also async..so may take time so directly pass in the function.
                setIsChecked(dpa?.defaultticked);
            })
            .catch(function (error) {
              
            })
    }


    useEffect(() => {

        loadDPA();

    }, [])


    useEffect(() => {
        props.setIsMarketingPreferencesChecked(isChecked);
        if (isChecked) {
            props.setErrorStates(errorStates => ({ ...errorStates, marketingPreferencesNotTicked: false }))
        }

    }, [isChecked])

    return (<div className="item-container">
        <label className="label">{t('marketingpreferences.title')}</label>
        <div className="item-wrapper">
            <Checkbox label={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dpa?.questiontext) }} />} checked={isChecked} onChange={() => { setIsChecked(!isChecked) }} id="marketingprefconsent" name="marketingprefconsent" />
            {props.errorStates.marketingPreferencesNotTicked ?
                <span role="alert" id='payment-not-selected'><ErrorBox>{t('payment.marketingpreferencesnotticked')}</ErrorBox></span>
                : ""}
        </div>
    </div>);
}