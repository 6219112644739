import { useTranslation } from 'react-i18next';
import { useState, useEffect, useContext, useRef, forwardRef, useImperativeHandle } from 'react';
import InputWrapperRefacture from '../../UI/Inputs/InputWrapperRefacture';
import Dropdown from '../../UI/Dropdown/Dropdown';
import { Company } from '../../Checkout/Company/Company';
import AddressSearch from 'react-loqate';
import SearchDropdown from '../../UI/Dropdown/SearchDropdown';
//import 'react-loqate/dist/react-loqate.cjs.development.css';
import '../../../i18n'
import { PaymentFormContext, updateBillingAddress, updateBillingCompany } from '../PaymentFormReducer';
import { checkIsBlank } from '../../Utilities/FieldValidations';
import ErrorBox from '../../UI/ErrorBox/ErrorBox';
import { APIGet, getEnvironment } from '../../API/APIRequest';
import { AddressFields } from './AddressFields';
import { format } from 'react-string-format';
import { UpdateBasket } from './Basket';

export const BillingDetails = forwardRef((props, ref) => {
    const { t, i18n } = useTranslation();
    const { flags, deliveryCountryCode, showCompany, setBasketErrors, purchasingFor, setBasket, basket, fid, loqateKey, vatDetails, setBillingCountryCode, billingCountryCode, deliveryAddress, IsFilledDelivery, selectedAddress, selectedCompany, setSelectedAddress, setSelectedCompany, deliveryCompany, IsFilled, setIsFilled, deliveryAddressStructure, sectionComplete, setSectionComplete, updateAndRefreshBasket, errorStates, setErrorStates, setcompanyProperties } = props;
    const [isChecked, setIsChecked] = useState(true);

    const [country, setCountry] = useState({});
    const [countryCode, setCountryCode] = useState("");
    const [initialFilledState, setInitialFilledState]=useState({});
    const [addressStructure, setAddressStructure] = useState([])
    const [companyProperties, setCompanyProperties] = useState(props.companyProperties)
    const formRef = useRef(null);
    const [countryList, setCountryList] = useState([]);
    const [environment, setEnvironment] = useState(getEnvironment());
    const [hasVatChanged, setVatChanged] = useState(null);
    
    const getCountryList = async () => {
        if(sessionStorage.getItem("countryList")){
           const list =  JSON.parse(sessionStorage.getItem("countryList"));
           setCountryList(list||[]);
          return;
        }

        return APIGet({
            "controller": "country",
            "action": "getcountries",
            "environment": environment,
            "identifier": null
        }).then((response) => {
            response.data.countries.sort((a, b) => {
                if (a.countryname < b.countryname) return -1;
                if (a.countryname > b.countryname) return 1;
                return 0;
              });
             
            sessionStorage.setItem("countryList", JSON.stringify(response.data.countries))
            setCountryList(response.data.countries)
        }).catch((errors) => {
            
        });
    }

   
    const getAddressStructure = async (value) => {
   
        return APIGet({
            "controller": "country",
            "action": "getaddressstructure",
            "environment": environment,
            "identifier": value?.toLowerCase()
        }).then((response) => {
            let addressfields = response.data.addressfields;
            setAddressStructure(addressfields)
            getCompanyField(addressfields)
            if (flags.collectbillingaddress) {
                addressFieldsMap(addressfields)
            }
        }).catch((errors) => {
          
        });
    }
    
    const addressFieldsMap = (addressfields) => {
        addressfields.map((field, index) => {
          if (field.fieldName !== "COUNTRY") {
            //so that on state change from manual to lookup we can retain the original isFilled state
            setInitialFilledState(initialFilledState => ({...initialFilledState, [field.fieldName]: { ...initialFilledState[field.fieldName], filled: true, mandatory: field.mandatory } }));
            }
        })
      }

    //company field's props to be extracted from addressFields so to be pushed into company component
    //specific to country
    const getCompanyField = (addressfields) => {
        let companyField = addressfields.find((item, i) => {
            if (item.fieldName === "COMPANY") {
                return item
            }});
       setCompanyProperties(companyField)
    }

    //on mount
    useEffect(() => {
        if (!countryList.length) {
            getCountryList();
        }
        if (deliveryAddress.COUNTRY) {
          setSelectedAddress({ ...selectedAddress, COUNTRY: deliveryAddress?.COUNTRY })
          setIsFilled(IsFilled => ({ ...IsFilled, COUNTRY: { filled: true, mandatory: true } }));
            
        }
        if (deliveryCompany.COMPANY) {
            setSelectedCompany({
                COMPANY: deliveryCompany?.COMPANY,
                CompanyId: deliveryCompany?.CompanyId || null
              });
        }
        if (deliveryCountryCode) {
            setCountryCode(deliveryCountryCode)
        }

    }, [])  

   //if no postal address is req then using delivery country code to fetch address structure in billing
    useEffect(() => {
      setCountryCode(deliveryCountryCode);
            if(!flags.isdeliverypostaladdressrequired && deliveryCountryCode){
                getAddressStructure(deliveryCountryCode)
            }
    }, [deliveryCountryCode]);

    useEffect(() => {
        
        if(flags.isdeliverypostaladdressrequired && !countryCode){
            setAddressStructure(deliveryAddressStructure);
            addressFieldsMap(deliveryAddressStructure);
            getCompanyField(deliveryAddressStructure); //on recieving address structure from delivery
            }
    }, [deliveryAddressStructure]);

    useEffect(()=>{
        setBillingCountryCode(countryCode)
    },[countryCode])
    
    useEffect(() => {
        if (deliveryAddress?.COUNTRY) {
            setSelectedAddress({ ...selectedAddress, COUNTRY: deliveryAddress?.COUNTRY })
            setIsFilled({ ...IsFilled, COUNTRY: { filled: true, mandatory: true } })
         
        }
    }, [deliveryAddress?.COUNTRY])


   //when checkbox is there on DOM
    useEffect(() => {
        if (isChecked && flags.collectbillingaddress) {
            setSelectedAddress(deliveryAddress);
            setIsFilled(IsFilledDelivery);
         }
        else {
            setSelectedAddress({ COUNTRY: deliveryAddress?.COUNTRY })
       }

    }, [isChecked])

    
 
    //on selection of country we need address structure from database
    const handleOnCountrySelect = (value) => {
        setCountry(value);
        updateAndRefreshBasket(value.countrycode,"origincountrycode");
         if (value.countrycode && (countryCode !== value?.countrycode) && flags.collectbillingaddress) {
            getAddressStructure(value?.countrycode)
         }
        if (value) {
        setIsFilled({COUNTRY: { ...IsFilled.COUNTRY, filled: true } })
        }
        setCountryCode(value?.countrycode)
        if(value?.countrycode !== countryCode)
        setSelectedAddress(selectedAddress=>({...selectedAddress , COUNTRY: value?.countryname }))  //blanking out the obj data except for country
    
    }
    
   //country field
    let countryField = <> <div className="item-container">
        <div className="item-wrapper">
            <SearchDropdown
                label={t('checkout.billingcountrylabel')}
                optionLabel={t('placeholder.addresssearchdropdown')}
                id="billing-country"
                name="billing-country"
                type="text"
                inputFieldType="text"
                aria-describedby="billing-country-error"
                inputSize="full-width"
                invalidClass={IsFilled.COUNTRY && !IsFilled?.COUNTRY?.filled && "Invalid-input"}
                aria-invalid={IsFilled.COUNTRY && !IsFilled?.COUNTRY?.filled}
                setValue={handleOnCountrySelect}
                val={selectedAddress.COUNTRY}
                options={countryList && countryList}
                dropdownName="country"
                keyProperty="countrycode"
                nameProperty="countryname"
                valueProperty="countrycode"
            />
        </div>
        {IsFilled.COUNTRY && !IsFilled?.COUNTRY?.filled &&
            <span role="alert" id="country-errors">
                <ErrorBox>{format(t('addressfields.blankerrortext'), "country")}</ErrorBox>
            </span>}
    </div>
    </>
    const deliveryAddressOptional = () => {
        // Get an array of [key, value] pairs
        const entries = Object.entries(props?.deliveryAddress).filter(([key, value]) => key !== 'SOURCE');
        const twoEntry = entries.find(([key, value]) =>  key='COUNTRY'); 
        // Add the 'two' entry to the end of the filtered entries
        if (twoEntry) {
            entries.shift(twoEntry);
             entries.push(twoEntry);
        }
        return (
            <>
                {props.deliveryCompany.COMPANY ? props.deliveryCompany.COMPANY + ', ' : '' }  
                {entries.map(([key, value], index) => (
                   <>
                         {value}
                        {index < entries.length - 1 && ', '}
                    </>
                ))} 

            </>
        )
    }
    return (
        <>
            {/*billing details is the same as delivery details-checkbox*/}
            {/*print, collect billing address*/}
            {(flags?.isdeliverypostaladdressrequired && flags?.collectbillingaddress) ?
                <>
                    <div className="item-container">
                        <div className="item-wrapper">
                            <InputWrapperRefacture
                                id="sameaddress"
                                name="sameaddress"
                                inputWidth="small"
                                type="text"
                                ref={formRef}
                                labelText={t('billingdetails.questionsameaddress')}
                                inputFieldType="checkbox"
                                checked={!isChecked}
                                onChange={() => { setIsChecked(!isChecked) }}
                            />
                        </div>
                    </div>
                    {isChecked && <div className="item-container">
                        <div className="item-wrapper">
                            <div className="detail-wrapper">
                                 {deliveryAddressOptional()}
                            </div>
                        </div>
                     </div>
                    }
                </> : ""}

            {(!(flags?.isdeliverypostaladdressrequired && flags?.collectbillingaddress) || !isChecked) ? countryField : ""} {/*included inside the checkbox}  
            {/* shows up in all flows except print variant, collect billing address*/}


            {((!flags?.isdeliverypostaladdressrequired && flags?.collectbillingaddress) || !isChecked) ?
                <AddressFields isChecked={isChecked}
                    vatDetails={vatDetails}
                    setCompanyProperties={setCompanyProperties}
                    companyProperties={companyProperties}
                    addressType="billingAddress"
                    loqateKey={loqateKey}
                    showCompany={showCompany}
                    selectedAddress={selectedAddress}
                    addressStructure={addressStructure}
                    initialFilledState={initialFilledState}
                    setSelectedAddress={setSelectedAddress}
                    selectedCompany={selectedCompany}
                    setSelectedCompany={setSelectedCompany}
                    IsFilled={IsFilled}
                    setIsFilled={setIsFilled}
                    countryCode={countryCode && countryCode}   //passing countryCode for loqate calls.
                    loading={props.loading}
                    hasVatChanged={hasVatChanged} />
                : ""}
            

            {/*only shows up if a basket has hidecompanyoncheckout false and country also has company in address fields*/}
            {showCompany && companyProperties && basket ?
                <Company companyList={props.companyList}
                    vatDetails={vatDetails}
                    basket={basket}
                    setBasket={setBasket}
                    fid={fid}
                    setBasketErrors={setBasketErrors}
                    purchasingFor={purchasingFor}
                    companyProperties={companyProperties}
                    addressType="billingAddress"
                    IsFilled={IsFilled}
                    setIsFilled={setIsFilled}
                    selectedCompany={selectedCompany}
                    setSelectedCompany={setSelectedCompany}
                    setVatChanged={setVatChanged}
                    isVatChecked={props.onVatChecked}
                    isVatApplied={props.onVatApplied}
                    errorStates={errorStates}
                    setErrorStates={setErrorStates}
                    setcompanyProperties={setcompanyProperties }
            />
            :""}


        </>
    );
})

